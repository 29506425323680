/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import '@src/assets/scss/variables/_variables';
@import '@styles/base/bootstrap-extended/_variables';

.app-content {
	max-height: 100%;
	height: 100% !important;
	padding: 0 !important
}

.header-navbar-shadow {
	display: none !important;
}

.content-wrapper {
	height: 100% !important;
}

.main-menu {
	.fallback-spinner {
		position: absolute;

		.loading {
			.effects {
				width: 18px !important;
				height: 18px !important;
			}
		}
	}

	&.menu-light {
		.navigation {

			>li {				
				ul {
					li>a {
						border-radius: 6px;
						padding-right: 35px !important;
					}
				}
			}

			>.nav-item {
				&:first-child {
					padding-bottom: 1.3125rem;
					margin-bottom: 2rem;

					>a {
						padding: 10px 1rem 10px 0.5rem;
						margin: 0 15px 0 15px;
					}

					li {
						position: relative;

						a {
							svg {
								width: 20px !important;
								height: 20px !important;
							}
						}
					}

					&:after {
						content: '';
						display: flex;
						justify-content: center;
						position: absolute;
						bottom: 0;
						width: calc(100% - 30px);
						height: 1px;
						background-color: #EBE9F1;
						left: 15px;
						right: 15px;
					}
				}
			}

			.navigation-header {
				margin: 1.4rem 0 0.8rem 2.2rem;
			}

			.nav-item {
				margin-bottom: 7px !important;

				a {
					position: relative;		
				}

				.fallback-spinner {
					top: 0;
					right: 23px;	

					.loading {
						width: 24px;
						height: 24px;
											
					}
				}

				&.has-sub {
					.fallback-spinner {
						right: 8px;	
					}
				}

				&.active {
					a {
						border-color: $primary !important;
						box-shadow: 0 4px 18px -4px rgba($primary, 0.7) !important;
						background: $primary !important;
						color: white !important
					}

					.fallback-spinner {
						.loading {
							.effects {
								border-left-color: white !important;
							}
						}
					}
				}
			}
		}

		.navbar-header {
			display: flex;
			align-items: center;
			height: 5.45rem;
			padding-left: 1rem;

			.navbar-nav {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
			}

			.nav-item {
				display: flex;
				align-items: center;
			}

			.brand-logo img {
				max-width: 100%;
			}

			.navbar-brand {
				margin: 0;
			}
		}
	}

	&.expanded {
		&.menu-light {
			.avatar {
				margin-right: 0.5rem !important;
			}

			.navigation {

				>.nav-item {
					&:first-child {
						>a {
							padding: 10px 14px 10px 14px;
						}

					}
				}
			}
		}
	}
}

.wetu-logo {
	height: 50px;
}

.user-wrapper {
	font-size: 14px !important;
	width: 100%;

	>.d-flex {
		width: 72%;

		span {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.avatar-status-online {
		display: none;
	}

	.user-status {
		font-size: 12px !important;
	}
}

@media (max-width: 992px) {
	.app-content {
		display: none;
	}

	.main-menu {
		left: 0 !important;
		opacity: 1 !important;
		width: 100% !important;
	}

	.nav-item {
		&.nav-toggle {
			display: none !important;
		}
	}
}